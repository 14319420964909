import { ReactNode, ComponentProps } from 'react'
import Box from '@base/Box'

export type Props = ComponentProps<typeof Box> & {
  children: ReactNode
  innerProps?: ComponentProps<typeof Box>
}

const BoxContainer = ({ children, innerProps, ...props }: Props) => (
  <Box display='flex' width='100%' px={{ xs: 2, sm: 3, md: 4, lg: 5 }} py={5} {...props}>
    <Box display='flex' width='100%' maxWidth={1600} mx='auto' {...innerProps}>
      {children}
    </Box>
  </Box>
)

export default BoxContainer
