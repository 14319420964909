import { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput, { OutlinedInputProps } from '@material-ui/core/OutlinedInput'
import MuiSelect, { SelectProps } from '@material-ui/core/Select'
import { Omit } from '@material-ui/core'
import Typography from '@base/Typography'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  input: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 28,
    [breakpoints.up('md')]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  icon: {
    top: 'initial',
  },
}))

export type Props = SelectProps &
  Omit<OutlinedInputProps, 'labelWidth'> & {
    items: (string | number | { value: string | number; label?: string | ReactNode })[]
    labelWidth?: number
  }

const Select = ({ items, labelWidth = 0, ...props }: Props) => {
  const classes = useStyles({})

  return (
    <MuiSelect
      input={<OutlinedInput labelWidth={labelWidth} />}
      classes={{ root: classes.input, icon: classes.icon }}
      {...props}
    >
      {items.map((item, id) => (
        <MenuItem key={id} value={typeof item === 'object' ? item.value : item}>
          {typeof item === 'object' ? (
            typeof item.label === 'string' ? (
              <Typography>{item.label}</Typography>
            ) : (
              item.label
            )
          ) : (
            <Typography>{item}</Typography>
          )}
        </MenuItem>
      ))}
    </MuiSelect>
  )
}

export default Select
