import gql from 'graphql-tag'

export default gql`
  query getUserCurrency {
    user: getSelf {
      id
      currency
    }
  }
`
