import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import React, { ComponentProps, ReactNode } from 'react'
import cx from 'classnames'

import Typography from '@base/Typography'
import { Omit } from '@material-ui/core'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    borderRadius: 5,
    padding: spacing(2),
    marginBottom: spacing(2),
    fontSize: '16px',
    fontWeight: 600,
  },
  error: {
    backgroundColor: palette.customBg.error,
    color: palette.customCol.error,
  },
  info: {
    backgroundColor: palette.customBg.pippin,
    color: palette.text.primary,
  },
  success: {
    backgroundColor: palette.customBg.success,
    color: palette.customCol.success,
  },
  warning: {
    backgroundColor: palette.customBg.tequila,
    color: palette.text.primary,
  },
}))

type Props = {
  children: ReactNode
  level?: 'error' | 'info' | 'success' | 'warning'
  TextProps?: Omit<ComponentProps<typeof Typography>, 'children'>
}

const Alert = ({ children, level = 'error', TextProps }: Props) => {
  const classes = useStyles({})

  return (
    <div
      className={cx(classes.root, {
        [classes.error]: level === 'error',
        [classes.info]: level === 'info',
        [classes.warning]: level === 'warning',
      })}
    >
      <Typography color='inherit' variant='inherit' {...TextProps}>
        {children}
      </Typography>
    </div>
  )
}

export default Alert
