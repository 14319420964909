import { ComponentProps, useEffect } from 'react'
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks'
import { connect, DispatchProp } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import get from '@helpers/get'

import Box from '@base/Box'
import Select from '@base/Select'
import Typography from '@base/Typography'
import { useTranslation } from '@src/i18n'
import { SetWebLanguageMutation, SetWebLanguageMutationVariables } from '@gql/__generated__'
import { GetUserCurrencyQuery } from '@gql/__generated__'
import SET_WEB_LANGUAGE from '@gql/mutations/setWebLanguageMutation.api'
import GET_USER_CURRENCY from './localSettings/getUserCurrencyQuery.api'
import SAVE_CURRENCY, { saveCurrencyQuery } from './localSettings/saveCurrency.store'
import { WebLanguage, getDefaultLocale, getAllowedLocales } from '@src/api/locales'
import analytics from '@src/Analytics'
import { userSelectors } from '@store/modules/User'
import { getCurrencyForLanguage } from '@service/Currency'
import getLogger from '@src/Logger'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  select: {
    background: 'none',
  },
  lng: {
    width: 120,
    [breakpoints.up('md')]: {
      width: 125,
    },
  },
  cur: {
    width: 70,
    [breakpoints.up('md')]: {
      width: 75,
    },
  },
}))

type ReduxStateProps = ReturnType<typeof mapStateToProps>

type Props = ComponentProps<typeof Box> & ReduxStateProps & DispatchProp

const LocalSettings = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch: _ /* Do not pass dispatch to rest of props */,
  isLoggedIn,
}: Props) => {
  const classes = useStyles({})
  const { i18n } = useTranslation()
  const client = useApolloClient()
  const allowedLocales = getAllowedLocales()
  const [setWebLanguage] = useMutation<SetWebLanguageMutation, SetWebLanguageMutationVariables>(SET_WEB_LANGUAGE)
  const { data: { savedCurrency = null } = {} } = useQuery<saveCurrencyQuery>(SAVE_CURRENCY, {
    fetchPolicy: 'cache-only',
  })
  const { data } = useQuery<GetUserCurrencyQuery>(GET_USER_CURRENCY, {
    skip: !isLoggedIn,
  })
  const userCurrency = (get(data, 'user', 'currency') || '').toUpperCase() // some entries in db might be saved as lowercase

  useEffect(() => {
    if (!savedCurrency || !i18n.languages[0]) {
      return
    }

    // Determine preferred currencies by user's language settings:
    // 1) currency for web language
    // 2) currency for browser language
    const preferredCurrencies = [
      getCurrencyForLanguage(i18n.languages[0]),
      getCurrencyForLanguage(navigator.language),
    ].filter(curr => !!curr)
    const obj = {
      i18nLanguage: i18n.languages[0],
      navigatorLanguage: navigator.language,
      savedCurrency,
    }

    // Check if user's currency equals to one of preferred currencies
    if (!preferredCurrencies.length) {
      getLogger().info(obj, 'No preferred currency for selected language')
    } else if (~preferredCurrencies.indexOf(savedCurrency)) {
      getLogger().info(obj, 'User has preferred currency for selected language')
    } else {
      getLogger().info(obj, "User doesn't have preferred currency for selected language")
    }
  }, [savedCurrency, i18n.languages[0]])

  useEffect(() => {
    if (!userCurrency) return
    client.writeData({
      data: { savedCurrency: userCurrency },
    })
  }, [userCurrency])

  const changeLanguage = (event: React.ChangeEvent<{ value: string }>) => {
    const webLanguage = event.target.value as WebLanguage
    i18n.changeLanguage(webLanguage).catch(err => getLogger().error({ err }, 'Changing i18n language failed'))
    if (isLoggedIn) {
      setWebLanguage({
        variables: {
          webLanguage,
        },
      }).catch(err => {
        getLogger().error({ err }, 'Change language failed')
      })
    }
    analytics.pushEvent('locale/change', { locale: webLanguage })
  }

  return (
    <>
      {/* Do not display language switch for single locale */}
      {allowedLocales.length > 1 && (
        <Select
          value={i18n.languages[0] || getDefaultLocale().webLanguage}
          onChange={changeLanguage}
          className={cx(classes.select, classes.lng)}
          items={allowedLocales.map(({ webLanguage, label }) => ({
            value: webLanguage,
            label: (
              <Box display='flex' alignItems='center'>
                <Typography variant='body2'>{label}</Typography>
              </Box>
            ),
          }))}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  const userState = userSelectors.getSelf(state)

  return {
    isLoggedIn: userSelectors.isLoggedIn(userState),
  }
}

const LocalSettingsMapped = connect<ReduxStateProps>(mapStateToProps)(LocalSettings)

export default LocalSettingsMapped
